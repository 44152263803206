import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { DialogConfirmService } from '../../../../core/services/dialog/dialog-confirm.service';

interface ModalDialogConfig {
  color: 'primary' | 'danger';
  visible?: boolean;
  header?: boolean;
  title?: string;
  description?: string;
  button: string;
  buttonConfirm: ModalDialogButtonConfig;
}

interface ModalDialogButtonConfig {
  name: string;
  click?: () => void;
}

@Component({
  selector: 'app-warning-confirm',
  templateUrl: './warning-confirm.component.html',
  styleUrls: ['./warning-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningConfirmComponent implements OnInit {
  dialog: ModalDialogConfig;
  selectPrimary: boolean;
  selectDanger: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    private dialogConfirmService: DialogConfirmService
  ) {}

  ngOnInit(): void {
    this.dialogConfirmService.reference(this);
  }

  config(dialog: ModalDialogConfig): void {
    if (dialog?.color === 'primary') {
      this.selectPrimary = true;
      this.selectDanger = false;
    } else {
      this.selectDanger = true;
      this.selectPrimary = false;
    }

    this.dialog = {
      ...dialog,
    };

    this.cd.detectChanges();
  }

  hide(): void {
    if (this.dialog) {
      this.config({ ...this.dialog, visible: false });
    }
  }
}
