import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { DialogService } from 'primeng/dynamicdialog';
import { LoadingService } from '../../../views/merchant-manage/loading.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogWarningService } from '../../../core/services/dialog/dialog-warning.service';

@UntilDestroy()
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  providers: [DialogService],
})
export class LoadingComponent implements OnInit {
  isLoading: boolean;

  constructor(
    private loadingService: LoadingService,
    private dialogWarningService: DialogWarningService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadingService.loading$
      .pipe(untilDestroyed(this))
      .subscribe((check) => {
        this.isLoading = check;
        this.cd.detectChanges();
        console.log(check);
      });
  }

  options: AnimationOptions = {
    path: './assets/img/Loader.json',
  };

  onAnimate(animationItem: AnimationItem): void {}
}
