import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import {catchError, concatMap, EMPTY, from, Observable, retry, timeout} from 'rxjs';
import { map } from 'rxjs/operators';

import * as dayjs from 'dayjs';

import { environment } from 'src/environments/environment';
import { SecurityHelper } from '../helpers/security-helper';
import { AuthService } from '../services/auth/auth.service';
import { AppError } from '../errors/app-error';
import { DialogWarningService } from '../services/dialog/dialog-warning.service';
import { BaseHttpHeaderResponse } from '../services/base/base.model';
import { JWT } from '../helpers/jwt-helper';
import {EncryptionService} from "./encryption/encryption.service";

@Injectable()
export class HttpInterceptorCustom implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private dialogWarningService: DialogWarningService,
    private encryptionService: EncryptionService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let timeoutValue: number = 30000;
    if (
      req.url.indexOf('/assets/') !== -1 ||
      req.url.indexOf('https://storage.googleapis.com') !== -1
    ) {
      return next.handle(req).pipe(retry(2));
    }

    const uuid = SecurityHelper.UUID();
    const cipher = SecurityHelper.RandomString(16);
    const reqFinal = {
      ...req.body,
      headerReq: {
        ...req.body.headerReq,
        reqID: uuid,
        reqDtm: dayjs().format('YYYY-MM-DD HH:mm:ss:SSS'),
        reqChannel: 'self-management-on-app',
      },
      body: {
        ...req.body.body,
        branchSk: sessionStorage.getItem('branchSK'),
        branchId: sessionStorage.getItem('branchId'),
      },
    };

    console.log(reqFinal);

    console.log('%cREQUEST BODY\t\t:', 'color: #d2691e', reqFinal);


    return from(this.encryptionService.encrypt(reqFinal)).pipe(
      concatMap(({param1,param2,key}) => {
        const authorization = req.headers.get('Authorization');
        let request: HttpRequest<any>;

        if (authorization) {
          request = req.clone({
            body: { param1, param2 },
          });
        } else {
          if (!environment.encryptMode) {
            request = req.clone({
              setHeaders: {
                Authorization: 'Bearer ' + this.authService.getToken(),
              },
              body: {
                headerReq: {
                  ...req.body.headerReq,
                  reqID: uuid,
                  reqDtm: dayjs().format('YYYY-MM-DD HH:mm:ss:SSS'),
                  reqChannel: 'self-management-on-app',
                },
                body: {
                  ...req.body.body,
                  branchSk: new JWT(this.authService.getToken()).data.branchSk,
                },
              },
            });
          } else {
            request = req.clone({
              setHeaders: {
                Authorization: 'Bearer ' + this.authService.getToken(),
              },
              body: { param1, param2 },
            });

            if (request.url) {
              timeoutValue = 30000;
            }
          }
        }
        return next.handle(request).pipe(
          concatMap(async (res) => {
            if (!environment.encryptMode) {
              return res;
            }
            if (res instanceof HttpResponse) {
              // Decrypt response
              res = res.clone({
                body: await this.encryptionService.decrypt(res.body, key),
              });

              const responseToJson = JSON.parse(res.body);
              if (
                responseToJson.headerResp &&
                responseToJson.headerResp.statusCd !== '0000'
              ) {
                // Manage case not success
                this.log(req, res);
                const headerResp: BaseHttpHeaderResponse =
                  responseToJson.headerResp;
                const error = new AppError(res.status, headerResp.statusDesc);
                error.withInput({
                  request: req,
                  body: responseToJson,
                  bodyEncode: {
                    body: request.body,
                  },
                });
                error.withBody(responseToJson);
                error.withStatusCode(headerResp.statusCd);
                if (headerResp.errorDisplay) {
                  error.setDisplayError(headerResp.errorDisplay);
                }
                throw error;
              } else {
                // Return success response
                this.log(req, res);
                return res;
              }
            }
            return res;
          }),
          timeout(timeoutValue),
          catchError(async (error) => {
            if (error instanceof AppError) {
              this.handlerError(error);
              throw error;
            } else {
              const errorRes = await this.encryptionService.decrypt(error.error, key);
              const errorJson = JSON.parse(errorRes);
              const appError: AppError = new AppError(
                error.status,
                JSON.parse(errorRes).message
              );
              console.log(JSON.parse(errorRes));
              appError.withBody(errorJson);
              appError.withCause(error);
              appError.withInput(reqFinal);
              if (errorJson.headerResp.errorDisplay) {
                error.setDisplayError(errorJson.headerResp.errorDisplay);
              }
              this.handlerError(appError);
              throw appError;
            }
          })
        );
      })
    )
  }

  private handlerError(appError: AppError): void {
    switch (appError.code) {
      case 400: {
        this.dialogWarningService.showNoResponse();
        break;
      }
      case 401: {
        this.dialogWarningService.showNoResponse();
        break;
      }
      case 500: {
        this.dialogWarningService.showNoResponse();
        break;
      }
      default:
        break;
    }
  }

  private log(req: HttpRequest<any>, event: HttpResponse<any>): void {
    if (!environment.production) {
      const start = Date.now();
      const index = req.url.indexOf('/assets/');
      const elapsed = Date.now() - start;

      console.groupCollapsed('Fetch:', req.method, req.url, `${elapsed}ms`);
      console.log('%cELAPSED\t\t:', 'color: #885544', `${elapsed}ms`);
      console.log('%cMETHOD\t\t:', 'color: #449900', req.method);
      console.log('URL\t\t\t:', req.urlWithParams);
      console.log('\t\t\t>', '[Language]\t\t:' + req.headers.get('Language'));
      console.log(
        '\t\t\t>',
        '[Authorization]\t:' + req.headers.get('Authorization')
      );
      console.log('%cBODY\t\t:', 'color: #d2691e', req.body);
      console.log(
        '%cRESPONSE\t:',
        'color: #0099ff',
        index !== -1 ? event.body : JSON.parse(event.body)
      );
      console.groupEnd();
    }
  }
}
