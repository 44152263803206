import jwtDecode from 'jwt-decode';

interface JWTData {
  sub: string;
  exp: number;
  iat: number;
  merchantId: string;
  branchId: string;
  branchSk: string;
  typ: string;
  aud: string;
  iss: string;
  jti: string;
}

export class JWT {
  data: JWTData;

  constructor(token: string) {
    this.data = jwtDecode(token);
  }
}
