<div
  class="
    navigation
    flex
    align-items-center
    pt-2
    pb-2
    pl-3
    pr-3
    justify-content-between
  "
>
  <div
    (click)="onBackClick()"
    class="back-button flex align-items-center navigation-icon mr-2"
  >
    <img src="../../../../../assets/icon/icon-left.svg" />
  </div>
  <div class="navigation-title text-center w-full">
    {{ title }}
  </div>
</div>
