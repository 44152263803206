<ng-container *ngIf="dialog">
  <p-dialog
    [(visible)]="dialog.visible && !dialog.header"
    [blockScroll]="true"
    [closable]="false"
    [draggable]="false"
    [modal]="true"
    [resizable]="false"
    [showHeader]="false"
  >
    <div class="text-center">
      <img alt="" src="./././assets/icon/icon-warning-fill.svg" />
    </div>
    <div
      *ngIf="dialog.title"
      class="
        col-12
        line-height-34
        pb-2
        flex
        justify-content-center
        text-center
        fs-24
      "
    >
      <strong [innerHTML]="dialog.title"></strong>
    </div>
    <div *ngIf="dialog.description">
      <div
        [innerHTML]="dialog.description"
        class="
          col-12
          pt-0
          fs-16
          line-height-23
          flex
          text-center
          justify-content-center
          fc-dark-light-gray
        "
      ></div>
    </div>
    <div
      *ngIf="dialog.button.name"
      class="col-12 mt-3 justify-content-center text-center"
    >
      <button
        (click)="dialog.button.click && dialog.button.click()"
        class="custom-button primary fs-18 font-bold"
        type="button"
      >
        {{ dialog.button.name }}
      </button>
    </div>
  </p-dialog>
</ng-container>
