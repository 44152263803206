import { Injectable } from '@angular/core';

interface Location {
  lat?: number;
  lng: number;
}

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  constructor() {}

  navigateToLogin(): void {
    window.location.href = 'portal/login';
  }

  navigateToMapLocation(location?: Location): void {
    if (location) {
      window.location.href = `portal/map-location?lat=${location.lat}&lng=${location.lng}`;
    } else {
      window.location.href = 'portal/map-location';
    }
  }

  navigationSetting(): void {
    window.location.href = 'portal/setting';
  }
}
