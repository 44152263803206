import { Injectable } from '@angular/core';
import { WarningConfirmComponent } from '../../../shared/components/dialog/warning-confirm/warning-confirm.component';

interface ModalDialogConfig {
  color: 'primary' | 'danger';
  visible?: boolean;
  header?: boolean;
  title?: string;
  description?: string;
  button: string;
  buttonConfirm: ModalDialogButtonConfig;
}

interface ModalDialogButtonConfig {
  name: string;
  click?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class DialogConfirmService {
  private component: WarningConfirmComponent;

  constructor() {}

  reference(component: WarningConfirmComponent): void {
    this.component = component;
  }

  customized(config: ModalDialogConfig): WarningConfirmComponent {
    if (this.component) {
      this.component.config(config);
    }
    return this.component;
  }

  hide(): void {
    if (this.component.dialog) {
      this.component.config({ ...this.component.dialog, visible: false });
    }
  }

  showCancelEdit(callBack?: () => void): void {
    const dialog = this.customized({
      color: 'primary',
      visible: true,
      title: 'คุณต้องการที่จะยกเลิกการ<br>แก้ไขใช่หรือไม่',
      description: 'หากยกเลิก ข้อมูลจะไม่ถูกบันทึก',
      button: 'ทำรายการต่อ',
      buttonConfirm: {
        name: 'ยกเลิกการแก้ไข',
        click: () => {
          if (callBack) {
            callBack();
            this.hide();
          } else this.hide();
        },
      },
    });
  }
}
