import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth/auth.service';
import { IdleSessionTimeout } from 'idle-session-timeout';
import { environment } from '../environments/environment';
import Timeout, { TimeoutInstance } from 'smart-timeout';
import { SessionStorage } from './core/storage/session.storage';
import { DialogWarningService } from './core/services/dialog/dialog-warning.service';
import { JWT } from './core/helpers/jwt-helper';
import { milliToSecond } from './core/helpers/convert-helper';
import { PortalService } from './core/services/portal/protal.service';
import { PrimeNGConfig } from 'primeng/api';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  session?: IdleSessionTimeout;
  tokenTimeOut?: TimeoutInstance;

  constructor(
    private authService: AuthService,
    private sessionStorage: SessionStorage,
    private dialogWarningService: DialogWarningService,
    private portalService: PortalService,
    private config: PrimeNGConfig,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.authService.$session.subscribe({
      next: (session) => {
        if (session) {
          if (this.tokenExpire()) {
            this.onTimeout();
          } else {
            this.startSessionTimeOut();
            this.startTokenTimeout();
          }
        }
      },
    });
    this.http.get<any>('/assets/i18n/th.json').subscribe({
      next: (data) => {
        this.config.setTranslation(data.primeng);
      },
    });
  }

  ngOnDestroy(): void {
    this.tokenTimeOut?.clear(true);
    this.session?.dispose();
  }

  private tokenExpire(): boolean {
    const jwt = new JWT(this.authService.getToken());
    const currentTime = milliToSecond(new Date().getTime());
    return jwt.data.exp < currentTime;
  }

  private startSessionTimeOut(): void {
    this.session = new IdleSessionTimeout(
      environment.sessionTimeout * 60 * 1000
    );
    this.session.onTimeOut = () => {
      this.clearSession();
      this.onTimeout();
    };
    this.session.start();
  }

  private startTokenTimeout(): void {
    this.tokenTimeOut = Timeout.instantiate(
      'tokenTimeOut',
      () => {
        this.clearSession();
        this.onTimeout();
      },
      environment.tokenTimeout * 60 * 1000
    );
  }

  private clearSession(): void {
    this.sessionStorage.clear();
  }

  private onTimeout(): void {
    this.dialogWarningService.showSessionTimeout(() => {
      this.portalService.navigationSetting();
    });
  }
}
