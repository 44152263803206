import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WarningComponent } from 'src/app/shared/components/dialog/warning/warning.component';
import { AppError } from '../../errors/app-error';

interface ModalDialogConfig {
  visible?: boolean;
  header?: boolean;
  title?: string;
  description?: string;
  button: ModalDialogButtonConfig;
}

interface ModalDialogButtonConfig {
  name: string;
  click?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class DialogWarningService {
  private component: WarningComponent;
  private router: Router;

  constructor() {}

  reference(component: WarningComponent): void {
    this.component = component;
  }

  customized(config: ModalDialogConfig): WarningComponent {
    if (this.component) {
      this.component.config(config);
    }
    return this.component;
  }

  hide(): void {
    if (this.component.dialog) {
      this.component.config({ ...this.component.dialog, visible: false });
    }
  }

  showErrorDisplay(error: AppError, callBack?: () => void): WarningComponent {
    const errorDisplay = error.getDisplayError();
    return this.customized({
      visible: true,
      title: errorDisplay?.title,
      description: errorDisplay?.message,
      button: {
        name: 'ตกลง',
        click: () => {
          console.log(callBack);
          if (callBack) {
            callBack();
            this.hide();
          } else this.hide();
        },
      },
    });
  }

  showNoResponse(callBack?: () => void): WarningComponent {
    return this.customized({
      visible: true,
      title: 'เว็บไซด์ไม่พร้อมใช้งาน<br>ในขณะนี้',
      description: 'กรุณาลองใหม่อีกครั้ง',
      button: {
        name: 'ตกลง',
        click: () => {
          if (callBack) {
            callBack();
            this.hide();
          } else this.hide();
        },
      },
    });
  }

  showSessionTimeout(callBack?: () => void): WarningComponent {
    return this.customized({
      visible: true,
      title: 'คุณไม่ได้ทำรายการภาย<br>ในเวลาที่กำหนด',
      description: 'กรุณาทำรายการใหม่อีกครั้ง',
      button: {
        name: 'ตกลง',
        click: () => {
          if (callBack) {
            callBack();
            this.hide();
          } else this.hide();
        },
      },
    });
  }

  showIDNotExist(callBack?: () => void): WarningComponent {
    return this.customized({
      visible: true,
      title: 'ไม่สามารถแก้ไขข้อมูลได้',
      description: 'เนื่องจากรายการนี้ถูกลบไปแล้ว โดยอุปกรณ์อื่น',
      button: {
        name: 'ตกลง',
        click: () => {
          if (callBack) {
            callBack();
            this.hide();
          } else this.hide();
        },
      },
    });
  }

  LimitProductException(callBack?: () => void): WarningComponent {
    return this.customized({
      visible: true,
      title: 'ไม่สามารถอัปโหลดข้อมูลเพิ่มได้',
      description:
        'กรุณาตรวจสอบว่าข้อมูลที่ต้องการอัปโหลด มีจำนวนรายการเกินกว่าที่กำหนดหรือไม่ และลองใหม่อีกครั้ง',
      button: {
        name: 'ตกลง',
        click: () => {
          if (callBack) {
            callBack();
            this.hide();
          } else this.hide();
        },
      },
    });
  }

  showBlocked(callBack?: () => void): WarningComponent {
    return this.customized({
      visible: true,
      title: 'ไม่สามารถเข้าใช้งานได้',
      description:
        'เนื่องจากร้านค้าถุงเงินของคุณ<br>ถูกระงับการใช้งาน หากมีข้อสงสัย<br>กรุณาติดต่อ Call Center 02-111-1122',
      button: {
        name: 'ตกลง',
        click: () => {
          if (callBack) {
            callBack();
            this.hide();
          } else this.hide();
        },
      },
    });
  }

  showDeleteMerchant(callBack?: () => void): WarningComponent {
    return this.customized({
      visible: true,
      title: 'ไม่พบข้อมูลร้านค้าในระบบ',
      description:
        'หากคุณไม่ได้แจ้งยกเลิกการใช้งานถุงเงิน<br>กรุณาติดต่อ Call Center 02-111-1122<br>เพื่อรับการช่วยเหลือ',
      button: {
        name: 'ตกลง',
        click: () => {
          if (callBack) {
            callBack();
            this.hide();
          } else this.hide();
        },
      },
    });
  }

  showWrongFileFormat(callBack?: () => void): WarningComponent {
    return this.customized({
      visible: true,
      title: 'ไม่สามารถอัปโหลดรูปภาพ<br>ร้านค้าได้',
      description:
        'เนื่องจากรูปแบบไฟล์ภาพไม่ถูกต้อง<br>โปรดตรวจสอบให้แน่ใจว่าไฟล์รูปภาพ<br>เป็นไฟล์รูปแบบ JPG, JPEG และ PNG เท่านั้น',
      button: {
        name: 'ตกลง',
        click: () => {
          if (callBack) {
            this.hide();
          } else this.hide();
        },
      },
    });
  }

  showCannotUploadImage(callBack?: () => void): WarningComponent {
    return this.customized({
      visible: true,
      title: 'ไม่สามารถอัปโหลดรูปภาพ<br>ร้านค้าได้',
      description: 'กรุณาทำรายการใหม่อีกครั้ง',
      button: {
        name: 'ตกลง',
        click: () => {
          if (callBack) {
            callBack();
            this.hide();
          } else this.hide();
        },
      },
    });
  }

  showLimitUploadImage(callBack?: () => void): WarningComponent {
    return this.customized({
      visible: true,
      title: 'ไม่สามารถอัปโหลดรูปภาพ<br>ร้านค้าได้',
      description:
        'เนื่องจากอัปโหลดรูปเกินจำนวนที่ระบบกำหนด<br>ไว้ หากต้องการอัปโหลดรูปเพิ่มเติม กรุณารอ<br>30 วัน หลังจากอัปโหลดรูปครั้งแรก',
      button: {
        name: 'ตกลง',
        click: () => {
          if (callBack) {
            callBack();
            this.hide();
          } else this.hide();
        },
      },
    });
  }

  showUploadBadImage(callBack?: () => void): WarningComponent {
    return this.customized({
      visible: true,
      title: 'ตรวจพบรูปภาพหรือเนื้อหา<br>ที่ไม่เหมาะสม',
      description: 'กรุณาอัปโหลดรูปภาพหรือระบุเนื้อหาใหม่อีกครั้ง',
      button: {
        name: 'ตกลง',
        click: () => {
          if (callBack) {
            callBack();
            this.hide();
          } else this.hide();
        },
      },
    });
  }
}
