import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../layout.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-merchant-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  title: string = 'รายละเอียดร้านค้า';

  constructor(private layoutService: LayoutService) {}

  ngOnInit(): void {
    this.layoutService.title$.pipe(untilDestroyed(this)).subscribe({
      next: (title) => {
        this.title = title;
      },
    });

    this.layoutService.backClicked$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        console.log('Navigate Back');
      },
    });
  }

  onBackClick(): void {
    this.layoutService.onBackClick();
  }
}
