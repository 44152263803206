import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CardModule } from 'primeng/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { WarningComponent } from './components/dialog/warning/warning.component';
import { ButtonComponent } from './components/button/button.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { TranslocoRootModule } from '../transloco-root.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CardComponent } from './components/card/card.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MapComponent } from './components/map/map.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InputTextModule } from 'primeng/inputtext';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { WarningConfirmComponent } from './components/dialog/warning-confirm/warning-confirm.component';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputComponent } from './components/input/input.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { SkeletonModule } from 'primeng/skeleton';
import { DatepickerYearThaiDirective } from './directives/datepicker-year-thai.directive';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { LoadingComponent } from './components/loading/loading.component';
import { LottieModule } from 'ngx-lottie';
import { TranslocoModule } from '@ngneat/transloco';
import { AutoCompleteComponent } from './components/autoComplete/autoComplete.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

export function playerFactory(): any {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    WarningComponent,
    ButtonComponent,
    ToggleComponent,
    CardComponent,
    MapComponent,
    WarningConfirmComponent,
    InputComponent,
    TextAreaComponent,
    DatepickerYearThaiDirective,
    LoadingComponent,
    AutoCompleteComponent,
  ],

  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslocoRootModule,
    DynamicDialogModule,
    NgxMaskModule.forRoot(maskConfig),
    DialogModule,
    CalendarModule,
    RadioButtonModule,
    InputTextareaModule,
    InputTextModule,
    CheckboxModule,
    InputSwitchModule,
    AutoCompleteModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: [
    CardModule,
    ButtonModule,
    RippleModule,
    CommonModule,
    DynamicDialogModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonComponent,
    ToggleComponent,
    ProgressSpinnerModule,
    MessageModule,
    MessagesModule,
    NgxMaskModule,
    DropdownModule,
    CardComponent,
    MapComponent,
    NgxPageScrollModule,
    DialogModule,
    FileUploadModule,
    DragDropModule,
    ImageCropperModule,
    NgxPageScrollCoreModule,
    InputTextModule,
    InputTextareaModule,
    WarningComponent,
    WarningConfirmComponent,
    CalendarModule,
    RadioButtonModule,
    SkeletonModule,
    TextAreaComponent,
    InputComponent,
    DatepickerYearThaiDirective,
    CheckboxModule,
    InputSwitchModule,
    AutoCompleteModule,
    LoadingComponent,
    TranslocoModule,
    AutoCompleteComponent,
  ],
  providers: [DialogService, DatePipe],
})
export class SharedModule {}
