import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  readonly loading$: Observable<boolean>;
  private _loading: Subject<boolean>;

  constructor() {
    this._loading = new BehaviorSubject<boolean>(false);
    this.loading$ = this._loading.asObservable();
  }

  show() {
    this._loading.next(true);
  }

  hide() {
    this._loading.next(false);
  }
}
