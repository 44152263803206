import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Option } from './base.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BaseHttpService {
  constructor(private httpClient: HttpClient) {}

  get<T>(url: string, query: any = null): Observable<any> {
    return this.httpClient.get(url + this.convertObjectToQueryString(query));
  }

  post<T>(url: string, body: any, option?: Option): Observable<any> {
    return this.httpClient
      .post(url, body, {
        ...option,
        responseType: 'text',
      })
      .pipe(map((data) => JSON.parse(data)));
  }

  put<T>(url: string, body: any = null): Observable<T> {
    return this.httpClient.put<T>(url, body);
  }

  patch<T>(url: string, body: any = null): Observable<T> {
    return this.httpClient.patch<T>(url, body);
  }

  delete<T>(url: string, query: any = null): Observable<T> {
    return this.httpClient.delete<T>(
      url + this.convertObjectToQueryString(query)
    );
  }

  private convertObjectToQueryString(values: any): string {
    if (!values) {
      return '';
    }

    const query = Object.keys(values)
      .map((key) => key + '=' + values[key])
      .join('&');

    return '?' + query.substring(1);
  }
}
