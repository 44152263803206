export const environment = {
  version: '2.0.9-dev',
  production: false,
  encryptMode: true,
  apiUrl: 'https://app-sit-v2.tungngern-gateway.arisetech.dev',
  sessionTimeout: 15,
  tokenTimeout: 60,
  encode: {
    publicKey: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjo2fj5clnu4DNNQmSwx/
XUymIi02dywlXqA6SV2qOAiJXvWki3Teh8rPCpRS3dFS6D1xxjIyGufMIAT+y3Mp
CUI8o46v8Qs6WIwFuszv6/Q4MPBPH3EY2peQPR0GyX1atGD2pg9DE7L47ZDzvETR
tjpeGx+/qPWpYmku8qo6jgUg3um8vy9M2fTRKv65eikeDVpICZqNxH7hy01TRO9k
AlCBntXhfOLB98UbwW1tyrFh5Tt+mLz9mmgbc/KK+walKMGUiL+nvSAXjovAV1ZT
/iSBxscZ9G3juX0qxZut3lxzsAVVCs///HU6zv3r4DV+7YS0+V8oTANRoixD/t99
3QIDAQAB
-----END PUBLIC KEY-----`,
  },
};
