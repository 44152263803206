import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GetAccessTokenInput, GetAccessTokenResponse } from './auth.model';
import { BaseHttpService } from '../base/base-http.service';
import { SessionStorage } from '../../storage/session.storage';
import { sitSession} from './fix-session';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  $session: Observable<string>;

  private _sessionSubject: BehaviorSubject<string>;
  private token: string;

  constructor(
    private http: BaseHttpService,
    private sessionStorage: SessionStorage
  ) {
    const token = this.sessionStorage.getItem('session');
    // const token = sitSession;
    this._sessionSubject = new BehaviorSubject<string>('');
    this.$session = this._sessionSubject.asObservable();

    if (token) {
      this.token = token;
      this._sessionSubject.next(token);
    }
  }

  isLogin(): boolean {
    return !!this.token;
  }

  getToken(): string {
    return this.token;
  }

  setSession(token: string): void {
    this.token = token;
    this._sessionSubject.next(token);
    this.sessionStorage.setItem('session', token);
  }

  getAccessToken(
    payload: GetAccessTokenInput
  ): Observable<GetAccessTokenResponse> {
    return this.http.post(
      environment.apiUrl +
        '/merchant-self-management/web-search/token-generate',
      {
        branchSk: payload.branchSk,
      },
      {
        headers: {
          Authorization: 'Bearer ' + payload.token,
        },
      }
    );
  }
}
