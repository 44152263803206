<ng-container *ngIf="dialog" [class]="dialog.color">
  <p-dialog
    #pDialog1
    [(visible)]="dialog.visible && !dialog.header"
    [blockScroll]="true"
    [closable]="false"
    [draggable]="false"
    [modal]="true"
    [resizable]="false"
    [showHeader]="false"
    class="border-round-sm"
  >
    <div *ngIf="selectDanger" class="text-center">
      <img alt="" src="./././assets/icon/icon-ic-alert-fill-red.svg" />
    </div>
    <div *ngIf="selectPrimary" class="text-center">
      <img alt="" src="./././assets/icon/icon-warning-fill.svg" />
    </div>

    <div
      *ngIf="dialog.title"
      class="
        col-12
        line-height-34
        pb-2
        flex
        justify-content-center
        text-center
        fs-24
      "
    >
      <strong [innerHTML]="dialog.title"></strong>
    </div>

    <div *ngIf="dialog.description">
      <div
        [innerHTML]="dialog.description"
        class="
          col-12
          pt-0
          fs-16
          line-height-23
          flex
          text-center
          justify-content-center
          fc-dark-light-gray
        "
      ></div>
    </div>

    <div
      *ngIf="dialog.button && dialog.buttonConfirm"
      class="col-12 mt-3 justify-content-center text-center"
    >
      <button
        (click)="hide()"
        class="custom-button outlined fs-18 font-bold"
        type="button"
      >
        {{ dialog.button }}
      </button>
      <span class="m-2"></span>
      <button
        (click)="dialog.buttonConfirm.click && dialog.buttonConfirm.click()"
        *ngIf="selectDanger"
        class="custom-button danger fs-18 font-bold"
        type="button"
      >
        {{ dialog.buttonConfirm.name }}
      </button>
      <button
        (click)="dialog.buttonConfirm.click && dialog.buttonConfirm.click()"
        *ngIf="selectPrimary"
        class="custom-button primary fs-18 font-bold"
        type="button"
      >
        {{ dialog.buttonConfirm.name }}
      </button>
    </div>
  </p-dialog>
</ng-container>
