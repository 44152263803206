import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './views/merchant-manage/layout/layout.component';

const routes: Routes = [
  {
    path: 'merchant-manage',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./views/merchant-manage/merchant-manage.module').then(
            (mod) => mod.MerchantManageModule
          ),
      },
    ],
    // canActivate: [AuthGuard]
  },

  {
    path: 'auth',
    loadChildren: () =>
      import('./views/auth/auth.module').then((mod) => mod.AuthModule),
  },
  {
    path: 'portal',
    loadChildren: () =>
      import('./views/portal/portal.module').then((mod) => mod.PortalModule),
  },
  {
    path: '**',
    redirectTo: '/portal',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
