interface DisplayError {
  title: string;
  message: string;
}

export class AppError extends Error {
  private errorInput: any;
  private errorCause: any;
  private statusCode: any;
  private response: any;
  private displayError?: DisplayError;

  constructor(public code: number, message: string) {
    super(message);
  }

  withInput(data: any): void {
    this.errorInput = data;
  }

  withCause(data: any): void {
    this.errorCause = data;
  }

  withStatusCode(code: any): void {
    this.statusCode = code;
  }

  withBody(value: any): void {
    this.response = value;
  }

  getCause(): any {
    return this.errorCause;
  }

  getInput(): any {
    return this.errorInput;
  }

  getStatusCode(): string {
    return this.statusCode;
  }

  setDisplayError(error: DisplayError): void {
    this.displayError = error;
  }

  getDisplayError(): DisplayError | undefined {
    return this.displayError;
  }

  getErrorResponse<T>(): T {
    return this.response;
  }
}
