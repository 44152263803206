import { ErrorHandler, Injectable } from '@angular/core';
import { DialogWarningService } from '../services/dialog/dialog-warning.service';
import { AppError } from './app-error';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private modalService: DialogWarningService) {}

  handleError(error: any): void {
    if (!environment.production) {
      console.log(error);
    }
    if (error instanceof AppError) {
      this.modalService.showErrorDisplay(error);
    } else {
      this.modalService.showNoResponse();
    }
  }
}
