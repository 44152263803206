<div
  *ngIf="isLoading"
  class="
    absolute
    w-screen
    h-screen
    -mt-6
    flex
    justify-content-center
    align-items-center
    background
  "
>
  <div class="lottie-animation mt-10">
    <ng-lottie [options]="options" (animationCreated)="onAnimate($event)">
    </ng-lottie>
  </div>
</div>
