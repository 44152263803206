import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  readonly title$: Observable<string>;
  readonly backClicked$: Observable<void>;

  private _titleS: Subject<string>;
  private _backClicked$: Subject<void>;

  constructor() {
    this._titleS = new Subject<string>();
    this.title$ = this._titleS.asObservable();

    this._backClicked$ = new Subject<void>();
    this.backClicked$ = this._backClicked$.asObservable();
  }

  setTitle(title: string): void {
    this._titleS.next(title);
  }

  onBackClick(): void {
    this._backClicked$.next();
  }
}
