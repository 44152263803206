import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { DialogWarningService } from '../../../../core/services/dialog/dialog-warning.service';
import { DialogService } from 'primeng/dynamicdialog';

interface ModalDialogConfig {
  visible?: boolean;
  header?: boolean;
  title?: string;
  description?: string;
  button: ModalDialogButtonConfig;
}

interface ModalDialogButtonConfig {
  name: string;
  click?: () => void;
}

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningComponent implements OnInit {
  dialog: ModalDialogConfig;

  constructor(
    private cd: ChangeDetectorRef,
    private dialogWarningService: DialogWarningService
  ) {}

  ngOnInit(): void {
    this.dialogWarningService.reference(this);
  }

  config(dialog: ModalDialogConfig): void {
    this.dialog = {
      ...dialog,
    };
    this.cd.detectChanges();
  }
}
