import * as CryptoJS from 'crypto-js';
import * as Forge from 'node-forge';

export class SecurityHelper {
  public static UUID(): string {
    let dt = new Date().getTime();
    const randomVal = parseFloat(
      '0.' + CryptoJS.lib.WordArray.random(1).words[0].toString()
    );
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        // tslint:disable-next-line: no-bitwise
        const r = (dt + randomVal * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        // tslint:disable-next-line: no-bitwise
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }

  public static Encrypt(value: string, key: string): string {
    const encrypt: string = CryptoJS.AES.encrypt(value, key).toString();

    return encrypt;
  }

  public static Decrypt(value: string, key: string): string {
    const decrypt: string = CryptoJS.AES.decrypt(value, key).toString(
      CryptoJS.enc.Utf8
    );

    return decrypt;
  }

  public static UrlDecrypt(value: string, key: string): string {
    const decodeValue: string = decodeURIComponent(value);

    const decrypt: string = CryptoJS.AES.decrypt(decodeValue, key).toString(
      CryptoJS.enc.Utf8
    );

    return decrypt;
  }

  public static AESECBEncrypt(value: object, key: string): string {
    const encrypt = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypt.toString();
  }

  public static AESECBDecrypt(value: string, key: string): string {
    const decrypt = CryptoJS.AES.decrypt(
      // @ts-ignore
      { ciphertext: CryptoJS.enc.Base64.parse(value) },
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return decrypt.toString(CryptoJS.enc.Utf8);
  }

  public static RSAEncrypt(value: string, key: string): string | boolean {
    const ras = Forge.pki.publicKeyFromPem(key);

    return btoa(ras.encrypt(value));
  }

  public static UUIDEncode(value: string): string {
    const encode = btoa(value);

    const separate = encode.length / 8;

    let builder = '';
    for (let index = 0; index < 8; index++) {
      const data = encode.substring(
        index * separate,
        index * separate + separate
      );
      if (index % 2 === 0) {
        builder = builder + data;
      } else {
        builder = data + builder;
      }
    }

    return builder;
  }

  public static UUIDDecode(encode: string): string {
    const separates: string[] = new Array(8);

    const half = encode.length / 2;
    const halfs: string[] = [];
    for (let index = 0; index < 2; index++) {
      halfs.push(encode.substring(index * half, index * half + half));
    }

    let quarter = halfs[0].length / 4;
    for (let index = 0; index < 4; index++) {
      separates[7 - index * 2] = halfs[0].substring(
        index * quarter,
        index * quarter + quarter
      );
    }

    quarter = halfs[1].length / 4;
    for (let index = 0; index < 4; index++) {
      separates[index * 2] = halfs[1].substring(
        index * quarter,
        index * quarter + quarter
      );
    }

    return atob(separates.join(''));
  }

  public static RandomString(length: number): string {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const randomVal = parseFloat(
      '0.' + CryptoJS.lib.WordArray.random(1).words[0].toString()
    );

    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(randomVal * chars.length));
    }

    return result;
  }
}
